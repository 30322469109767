import { useState } from "react";
import { UploadFile } from "antd/lib/upload";
import { observer } from "mobx-react";
import { useFlags } from "launchdarkly-react-client-sdk";

import { useHistory } from "react-router-dom";

import log from "loglevel";
import { UserDepot } from "../../store/UserDepot";
import ModalBulkRoomUpload from "../components/CorePlans/ModalBulkRoomUpload";
import { ContentMode, openModalCorePlanWindow } from "../components/CorePlans/ModalCorePlanWindow";
import ModalSystemSettings from "../components/CorePlans/ModalSystemSettings/ModalSystemSettings";
import CorePlansListView from "../components/CorePlans/CorePlansListView";
import Header from "../components/HomePage/Header";
import { sendEnvelopeSingle } from "../../services/api/index";
import { showToastMessage } from "../../helpers/messages";
import { CorePlan } from "../../models/CorePlan";
import { appModel } from "../../models/AppModel";
import { Envelope } from "../../services/Envelope";

const HomePage = observer(() => {
  const [isSystemSettingsModalOpen, setIsSystemSettingsModalOpen] = useState(false);
  const [bulkAddModalOpen, setBulkAddModalOpen] = useState(false);
  const [bulkUploadFolders, setBulkUploadFolders] = useState<Map<string, UploadFile[]>>(null);
  const [uploadRevitFiles, setUploadRevitFiles] = useState<UploadFile[]>([]);
  const history = useHistory();

  const flags = useFlags();

  const onCreateCorePlanClick = () => {
    openModalCorePlanWindow(null, ContentMode.New);
  };

  const onSystemSettingsClick = () => {
    setIsSystemSettingsModalOpen(true);
  };

  const onFileChange = event => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async event => {
        const fileContent = event.target.result;
        try {
          const jsonObject = JSON.parse(fileContent.toString());
          const envelopeResponse: Envelope = await sendEnvelopeSingle(jsonObject, "core_plans");
          if ((envelopeResponse as any).response?.status == 200 || !envelopeResponse.meta.hasErrors) {
            showToastMessage("Success", `'${jsonObject.data[0].name}' was successfully saved`, { autoClose: 700 });
            const corePlan = new CorePlan().fromDTO(jsonObject.data[0], "APIdata");
            corePlan.updatedAt = new Date().toISOString();
            appModel.addCorePlan(corePlan);
          } else {
            log.error("Error sending envelope:", envelopeResponse);
            return;
          }
          return;
        } catch (error) {
          log.error("Error parsing JSON:", error);
        }
      };
      reader.readAsText(file);
    }
  };

  const onBulkUploadModalOpen = (uploadedCategoriesMap: Map<string, UploadFile[]>, uploadedRevitFilesList: UploadFile<any>[]) => {
    setBulkAddModalOpen(true);
    setIsSystemSettingsModalOpen(false);
    setBulkUploadFolders(uploadedCategoriesMap);
    setUploadRevitFiles(uploadedRevitFilesList);
  };

  const onSystemSettingsModalClose = () => {
    setIsSystemSettingsModalOpen(false);
  };

  const onBulkUploadCancel = () => {
    setBulkAddModalOpen(false);
    setIsSystemSettingsModalOpen(true);
    setBulkUploadFolders(null);
  };

  return (
    <>
      <Header
        userName={UserDepot.current?.userData.name}
        onSystemSettingsClick={onSystemSettingsClick}
        onCreateCorePlanClick={onCreateCorePlanClick}
        onFileChange={onFileChange}
      />
      <CorePlansListView flags={flags} />
      <ModalSystemSettings isOpen={isSystemSettingsModalOpen} handleModalClose={onSystemSettingsModalClose} handleBulkUploadModalOpen={onBulkUploadModalOpen} />
      <ModalBulkRoomUpload isOpen={bulkAddModalOpen} handleClose={onBulkUploadCancel} folders={bulkUploadFolders} revitFiles={uploadRevitFiles} />
    </>
  );
});

export default HomePage;
