import { Button, Tooltip } from "antd";
import { useRef } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";

import { Profile } from "./Profile";
import { APP_VERSION } from "../../../constants/versions";
import { revitFiles } from "../../../entities/revitFiles";
import { SettingsIcon } from "../common/SettingsIcon";

import "./Header.sass";
import ImportIcon from "../common/ExportIcon";

type HeaderProps = {
  userName: string;
  onSystemSettingsClick: () => void;
  onCreateCorePlanClick: () => void;
  onFileChange: (event) => void;
};

const Header = ({ userName, onSystemSettingsClick, onCreateCorePlanClick, onFileChange }: HeaderProps) => {
  const catalogVersion = revitFiles.catalogVersion;
  const versionsOverlay = (
    <>
      <span className="version-label">Studio Release Version {APP_VERSION} </span>
      {catalogVersion && <span className="version-label">Catalog Version {catalogVersion}</span>}
    </>
  );
  const fileInputRef = useRef(null);
  const onImportClick = () => {
    fileInputRef.current.click();
  };
  const flags = useFlags();

  return (
    <div className="header">
      <div className="content">
        <div className="left-container">
          <div className="logo">
            <img src="/Digital ops logo.svg" alt="DCP" title="DCP" />
          </div>
          <span>Welcome {userName || "User"}</span>
        </div>
        <div className="right-container">
          <div className="options">
            {flags["copyCorePlan"] && (
              <Tooltip title="Import Core Plan">
                <Button className="btn-export" icon={<ImportIcon />} onClick={onImportClick}></Button>
                <input type="file" ref={fileInputRef} style={{ display: "none" }} onChange={onFileChange} />
              </Tooltip>
            )}
            <Tooltip overlayClassName="version-tooltip" overlay={versionsOverlay} placement="bottomRight">
              <span className="version-item">V {APP_VERSION}</span>
            </Tooltip>
            <Profile />
            <Tooltip title="System Settings">
              <Button className="btn-rooms" icon={<SettingsIcon />} onClick={onSystemSettingsClick}></Button>
            </Tooltip>
          </div>
          <Button type="default" className="btn-new-corePlan" onClick={onCreateCorePlanClick}>
            Add Core Plan
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Header;
