import { SVGProps } from "react";

const ImportIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="29" height="29" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M12 4V14.586L9.707 12.293L8.293 13.707L13 18.414L17.707 13.707L16.293 12.293L14 14.586V4H12Z" fill="#FFFFFF" />
      <path d="M4 20H20V18H4V20Z" fill="#FFFFFF" />
    </svg>
  );
};

export default ImportIcon;
